.container-loader {
    align-items: center;
    background-color: #0f1f38;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    flex-direction: column;
    top: 0;
    z-index: 10000;
}
#log{
    width: 30%;
}
#Calque_2{
    transform-origin: center;
    animation: animb 2s linear infinite ;
}
#Calque_4{
    transform-origin: center;
    animation: animT 2s linear infinite ;
}

@keyframes animb {
    0%{

        transform: rotate(0deg);

    }
    20%{
        transform: rotate(5deg);

    }
    40%{
        transform: rotate(7deg);
    }
    60%{
        transform: rotate(7deg);

    }
    80%{
        transform: rotate(5deg);
    }
    100%{
        transform: rotate(0deg);
    }

}
@keyframes animT {
    0%{

        transform: rotate(0deg);

    }
    20%{
        transform: rotate(-5deg);

    }
    40%{
        transform: rotate(-7deg);
    }
    60%{
        transform: rotate(-7deg);

    }
    80%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(0deg);
    }

}
.container-loader span{
    color: #f47a71;
    font-weight: bold;
    position: absolute;
    text-align: center;
    margin-top: -30vh;
    left: 45vw;
}